import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/Default"
import Breadcrumb from "../components/commons/Breadcrumb"
import Container from "../components/layouts/container/Container"
import ColumnsThree from "../components/layouts/container/ColumnsThree"
import SectionTitle from "../components/commons/SectionTitle"
import ListEpisode from "../components/commons/ListEpisode"
import SidebarTopics from "../components/commons/SidebarTopics"

const Topics = ({ data, location, pageContext }) => {
  return (
    <Layout
      location={location}
      title={`Alle Folgen zu: ${pageContext.topic}`}
      desc={`Die Summe aller Dinge Podcast. Alle Folgen zu: ${pageContext.topic}`}
    >
      <Breadcrumb
        pages={[
          { page: "Alle Folgen", to: "/folgen/" },
          {
            page: `${pageContext.topic}`,
          },
        ]}
      />
      <Container className="my-8 mmd:my-16">
        <ColumnsThree>
          <div className="col-span-2 mb-6 mmd:mb-0">
            <SectionTitle
              title={`Alle Folgen zu: ${pageContext.topic}`}
              color={pageContext.color}
              className="mb-6"
            />
            <ListEpisode listArray={data.alleFolgen.nodes}
                          totalCount={data.alleFolgen.totalCount}
                          currentPage={pageContext.currentPage}
                           />
          </div>
          <div className="col-span-1 mmd:mt-16">
            <SidebarTopics />
          </div>
        </ColumnsThree>
      </Container>
    </Layout>
  )
}

export default Topics

export const query = graphql`
  query($topic: String) {
    alleFolgen: allCockpitFolgen(
      sort: { fields: folgennummer___value, order: DESC }
      filter: {
        thema: { value: { display: { eq: $topic } } }
        public: { value: { eq: true } }
      }
    ) {
      totalCount
      nodes {
        titel {
          value
        }
        slug {
          slug
        }
        thema {
          value {
            display
          }
        }
        titelbild {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        einleitung {
          value
        }
        folgennummer {
          value
        }
        datum {
          value
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
